//HOME STUDENT
$bg--color--main: #001235;

//SIDEBAR SIZE
$sidebar--size: 280px;

// * breakpoints: 
$mobile: 430px;
$tablet: 768px;
$desktop: 1024px;

.modal-container {
  display: block;
  position: fixed;
  justify-content: flex-end;
  align-self: center;
  left: 0;
  bottom: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  -webkit-transition: 0.5s;
  overflow: auto;
  transition: all 0.3s linear;
  background: rgba(209, 210, 227, 0.32);
  backdrop-filter: blur(5px);

  .modal-content {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    padding: 20px 20px 0 20px;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: #FDFDFD;
    margin: auto;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    width: 80%;
    height: 80%;
    overflow-y: auto;

    .content {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      gap: 30px;


      .content-top {
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        width: 100%;
        font-size: 22px;

        h2 {

          @media (max-width: 700px) {
            font-size: 20px;
          }
        }

        @media (max-width: 700px) {
          align-self: center;
        }

        .subtitle {
          font-size: 16px;

          @media (max-width: 700px) {
            font-size: 14px;
          }
        }
      }

      .content-mid {
        display: flex;
        flex-direction: column;
        padding: 5px;

        ul {
          display: flex;
          flex-direction: column;
          gap: 10px;

          li {
            font-size: 16px;
            height: 100%;

            @media (max-width: 700px) {
              font-size: 14px;
            }
          }

          p {
            font-size: 16px;

            @media (max-width: 700px) {
              font-size: 14px;
            }

            @media (max-width: 500px) {
              font-size: 12px;
            }
          }

          @media (max-width: 700px) {
            gap: 5px;
          }
        }

        a {
          font-weight: 500;
        }

        @media(max-width: $desktop) {
          overflow-y: auto;
          padding: 10px 0;
          height: 100%;
        }
      }

      .buttons {
        display: flex;
        flex-direction: row;
        align-self: flex-end;
        gap: 20px;


        img {
          position: absolute;
          right: 10%;
          width: 30px;
          height: 30px;

          @media (max-width: 600px) {
            display: none;
          }
        }


        @media (max-width: 900px) {
          align-self: center;
          gap: 10px;
        }

        @media (max-width: 700px) {
          button {
            font-size: 12px;
            margin-bottom: 10px;
          }
        }

        @media (max-width: 485px) {
          align-self: center;
          width: 100%;
          gap: 5px;
        }
      }

      @media (max-width:700px) {
        gap: 5px;
        min-height: auto;
      }
    }

    @media (min-width: 1200px) {
      padding: 0 80px;
    }

    @media (max-width: 450px) {
      gap: 0;
      justify-content: center;
    }

    @media (max-width: $tablet) {
      padding: 10px;
      height: 90%;
      width: 90%;
    }
  }

  @media (max-width: $tablet) {}
}

.btn {
  display: flex;
  position: relative;
  height: 40px;
  width: 170px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  gap: 20px;

  @media (max-width: 700px) {
    font-size: 12px;
  }
}

.blue {
  color: #060F9B;

  font-weight: 500;
}

.red {
  color: #AF0202;
  font-weight: 500;
}

.btn-blue-outline {
  background: #F9F9F9;
  border: 0.5px solid #060F9B;
  color: #060F9B;
}

.btn-blue {
  border: none;
  background: #060F9B;
  color: white;
}

.plan-unpausing {
  display: flex;
  justify-content: space-around;
  height: 90%;
  text-align: center;
  flex-direction: column;
  align-items: center;

  .top-content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;

    p {
      text-align: center;
      font-size: 16px;
      align-self: center;
      width: 75%;

      @media (max-width: 600px) {
        font-size: 14px;
      }
    }
  }


  img {
    position: absolute;
    right: 10%;
    width: 30px;
    height: 30px;

    @media (max-width: 600px) {
      display: none;
    }
  }

  .human {
    display: block;
    position: inherit;
    align-self: center;
    width: 350px;
    height: 350px;

    @media (max-width: 600px) {
      width: 250px;
      height: 250px;
    }
  }
}

a {
  color: #0066CC;
}

.sac {
  font-weight: 600;
  color: #0066CC;
}

a:hover {
  text-decoration: underline;
}

.italic {
  font-style: italic;
}